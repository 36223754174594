<template>
    <div class="loadfull loadblur">
        <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" fill="#FF2D55" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"
                 style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <rect x="0" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                             repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                             repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                             repeatCount="indefinite" />
                </rect>
                <rect x="8" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                             repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                             repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                             repeatCount="indefinite" />
                </rect>
                <rect x="16" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                             repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                             repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                             repeatCount="indefinite" />
                </rect>
            </svg>
        </div>
        <div class="loading_text">
            <p v-if="this.$store.state.findChannel === 'Y' && preUrl === '/space/updateFindChannel'">변경된 채널로 입장 중 입니다.</p>
            <p v-else-if="this.$store.state.findChannel === 'Y' && preUrl !== '/space/updateFindChannel'">채널로 입장 중 입니다.</p>
            <p v-else-if="curUpdate === 'updated'">변경된 큐레이션으로 선곡중입니다.</p>
            <p v-else-if="curUpdate === 'change'">채널에 입장중입니다.</p>
            <p v-else>플레이리스트를 불러오는 중 입니다.</p>
        </div>
    </div>
</template>
<script>
export default {
  name: 'SeongDongLoadingFull',
  props: {
    preUrl: String,
    curUpdate: String
  },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  }
};
</script>
<style scoped src="@/assets/css/seongdonglibary/seongdongfullloading.css"></style>
